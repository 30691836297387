.topButtonWrap{
  color: white;
  margin-right: 30px;
  height: 100%; 
  display: flex;
  align-items: center;
  justify-content: center
}

.clearButton {
  background-color: #898989;
  color: white;
  border: none;
  height: 80%;
  width: 100%;
}

.clearButtonHover{ 
    background-color: white !important;
    color: black !important;
    border: none;
    height: 80%;
    width: 100%;
}


.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid black;
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: absolute;
  height: calc(100vh - 70px);
  width: 100vw;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  /* --------------- */

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1.0;
  /* --------------- */

  /* typography */
  color: white;
  /* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #40b3ff;
  box-shadow: 0 0 13px 3px #40b3ff;
}